import { inject, Injectable, OnInit, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IPaginatedUsers } from '../interfaces/users.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private http = inject(HttpClient)
  baseUrl = environment.baseUrl;

  getUsers(limit: number, skip: number) {
    const url = `${this.baseUrl}/api/v1/user/${limit}/${skip}`
    return this.http.get<IPaginatedUsers>(url);
  }

  getUserFilter(filter: string, limit: number, skip: number) {
    const url = `${this.baseUrl}/api/v1/user/${limit}/${skip}?fullName=${filter}`
    return this.http.get<IPaginatedUsers>(url);
  }

  getUserProfile(id: string) {
    const url = `${this.baseUrl}/api/v1/user/${id}/profile`
    return this.http.get<any>(url)
  }

  updateRolUser(id: string, payload: {rol:string}) {
    const url = `${this.baseUrl}/api/v1/user/${id}/update-rol`
    return this.http.patch<any>(url, payload)
  }

}